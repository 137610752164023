import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { countryDialCodes } from "../../utils/phoneNumbers";
import {
  deserialize,
  findCountryCodeFromDialCode,
  getDefaultDialCode,
  serialize,
} from "../../utils/phoneNumbers/custom-phone-number-input-utils";
import CustomPhoneNumberInputDefault from "./CustomPhoneNumberInputDefault";
import CustomPhoneNumberInputLocked from "./CustomPhoneNumberInputLocked";

const CustomPhoneNumberInput = ({
  onBlur,
  onFocus,
  onChange,
  focused,
  locked,
  isVerified,
  defaultValue,
  defaultCountry,
  name,
  value,
}) => {
  const hiddenInput = useRef(null);
  const phoneNumberCurrentValue = useRef(null);
  const { t } = useI18n();

  const defaultDialCode = getDefaultDialCode(defaultCountry, defaultValue);

  const [phoneNumberInput, setPhoneNumberInput] = useState(() => {
    if (defaultValue) {
      return deserialize(defaultValue);
    }
    if (value) {
      return deserialize(value);
    }
    return {
      dialCode: defaultDialCode,
      phoneNumber: "",
      selectedCountry: defaultCountry || "",
    };
  });
  const { dialCode, phoneNumber, selectedCountry } = phoneNumberInput;

  useEffect(() => {
    // Updates phoneNumberInput and sets the phoneNumberCurrentValue ref
    setPhoneNumberInput((prev) => {
      if (value && value !== serialize(prev)) {
        return deserialize(value);
      }
      return prev;
    });
    if (hiddenInput.current && phoneNumberCurrentValue.current === null) {
      phoneNumberCurrentValue.current = hiddenInput.current.value;
    }
  }, [value]);

  useEffect(() => {
    // Tracks changes to phoneNumberCurrentValue and updates the hidden input,triggering the onChange callback
    if (hiddenInput.current) {
      const newInputRefValue = hiddenInput.current.value;
      if (phoneNumberCurrentValue.current !== newInputRefValue) {
        phoneNumberCurrentValue.current = newInputRefValue;
        onChange?.({ target: hiddenInput.current, type: "change" });
      }
    }
  }, [phoneNumberInput, onChange]);

  const updatePhoneNumberInput = useCallback((updates) => {
    setPhoneNumberInput((prev) => ({ ...prev, ...updates }));
  }, []);

  const sortedDialCodes = useMemo(() => {
    if (!countryDialCodes || !t) return [];

    const updatedDialCodes = countryDialCodes.map((obj) => ({
      ...obj,
      countryName: t(`countries.${obj.code}`),
    }));

    function compare(a, b) {
      if (a.countryName < b.countryName) return -1;
      if (a.countryName > b.countryName) return 1;
      return 0;
    }

    return [...updatedDialCodes].sort(compare);
  }, [t]);

  const onChangeDialCode = (dialCode) => {
    if (!/^\d*$/.test(dialCode)) {
      return false;
    }
    const matchingCountry = findCountryCodeFromDialCode(
      dialCode.replace(/^0/, ""),
    );
    if (matchingCountry) {
      updatePhoneNumberInput({
        dialCode,
        selectedCountry: matchingCountry ? matchingCountry.code : "",
      });
    } else {
      updatePhoneNumberInput({
        dialCode,
        selectedCountry: "",
      });
    }
  };

  const onSelectCountry = (countryCode) => {
    const matchingCountry = countryDialCodes.find(
      (countryDialCode) =>
        countryCode.toLowerCase() === countryDialCode.code.toLowerCase(),
    );
    if (matchingCountry) {
      updatePhoneNumberInput({
        dialCode: matchingCountry.dial_code,
        selectedCountry: countryCode,
      });
    } else {
      throw new Error("COUNTRY NOT FOUND IN DIAL CODE LIST");
    }
  };

  const onChangePhoneNumber = (phoneNumber) => {
    updatePhoneNumberInput({ phoneNumber });
  };

  const onBlurDialCode = () => {
    updatePhoneNumberInput({ dialCode: dialCode.replace(/^0/, "") });
    onBlur();
  };

  const onBlurPhoneNumber = () => {
    updatePhoneNumberInput({ phoneNumber: phoneNumber.replace(/^0/, "") });
    onBlur();
  };

  const className = classNames("CustomPhoneNumberInput", {
    Input: !locked,
    "Input--focused": !locked && focused,
    "CustomPhoneNumberInput--locked": locked,
  });

  const serializedValue = serialize({ dialCode, phoneNumber });

  return (
    <div className="CustomPhoneNumberInput-outer">
      {locked ? (
        <CustomPhoneNumberInputLocked
          t={t}
          isVerified={isVerified}
          dialCode={dialCode}
          phoneNumber={phoneNumber}
          className={className}
        />
      ) : (
        <CustomPhoneNumberInputDefault
          onFocus={onFocus}
          onBlur={onBlur}
          focused={focused}
          name={name}
          defaultValue={defaultValue}
          selectedCountry={selectedCountry}
          dialCode={dialCode}
          phoneNumber={phoneNumber}
          onBlurDialCode={onBlurDialCode}
          onBlurPhoneNumber={onBlurPhoneNumber}
          onChangeDialCode={onChangeDialCode}
          onChangePhoneNumber={onChangePhoneNumber}
          onSelectCountry={onSelectCountry}
          sortedDialCodes={sortedDialCodes}
          className={className}
        />
      )}
      <input
        data-testid="CustomPhoneNumberInput"
        type="hidden"
        name={name}
        ref={hiddenInput}
        value={serializedValue}
      />
    </div>
  );
};

CustomPhoneNumberInput.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  focused: PropTypes.bool.isRequired,
  locked: PropTypes.bool,
  isVerified: PropTypes.bool,
  defaultValue: PropTypes.string,
  defaultCountry: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default CustomPhoneNumberInput;
