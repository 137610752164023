import { countryDialCodes } from "./country-dial-codes";

export function getDefaultDialCode(defaultCountry, defaultValue) {
  if (defaultCountry && !defaultValue) {
    const defaultCountryDialCode = countryDialCodes.find(
      (countryDialCode) =>
        defaultCountry.toLowerCase() === countryDialCode.code.toLowerCase(),
    );

    return defaultCountryDialCode ? defaultCountryDialCode.dial_code : "";
  }
  return "";
}

export function findCountryCodeFromDialCode(dialCode) {
  return dialCode
    ? countryDialCodes.find(
        (countryDialCode) =>
          dialCode.replace(" ", "") ===
          countryDialCode.dial_code.replace(" ", ""),
      )
    : "";
}

export function serialize({ dialCode = "", phoneNumber = "" }) {
  if (phoneNumber === "") {
    return "";
  }

  return `${dialCode ? "+" : ""}${dialCode
    .replace(" ", "")
    .replace(/^0/, "")}${dialCode ? " " : ""}${phoneNumber
    .replace(" ", "")
    .replace(/^0/, "")}`;
}

export function deserialize(formattedPhoneNumber) {
  const phoneNumberRegex = /^\+[0-9]{1,5}\s[0-9]*/;

  if (
    formattedPhoneNumber.indexOf(" ") === -1 &&
    formattedPhoneNumber.startsWith("+") &&
    formattedPhoneNumber.length < 6
  ) {
    const dialCode = formattedPhoneNumber.substring(1);
    const matchingCountry = findCountryCodeFromDialCode(dialCode);
    return {
      dialCode,
      phoneNumber: "",
      selectedCountry: matchingCountry ? matchingCountry.code : "",
    };
  }

  if (!phoneNumberRegex.test(formattedPhoneNumber)) {
    return {
      dialCode: "",
      phoneNumber: formattedPhoneNumber,
      selectedCountry: "",
    };
  }

  const dialCodeSplitIndex = formattedPhoneNumber.indexOf(" ");
  const dialCode = formattedPhoneNumber
    .slice(0, dialCodeSplitIndex)
    .replace("+", "");
  const phoneNumber = formattedPhoneNumber.slice(dialCodeSplitIndex + 1);
  const matchingCountry = findCountryCodeFromDialCode(dialCode);
  return {
    dialCode,
    phoneNumber,
    selectedCountry: matchingCountry ? matchingCountry.code : "",
  };
}
