/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { flushSync } from "react-dom";
import PropTypes from "prop-types";
import { createRoot } from "react-dom/client";
import { useSwipeable } from "react-swipeable";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { canUseDOM } from "../../utils/dom";
import { Button, Link } from "../DesignSystem/Button/Button";
import Close from "../Icons/Close";
import StatusCard from "../StatusCard/StatusCard";
import "./Toaster.scss";

const intents = {
  brand: "brand",
  danger: "danger",
  neutral: "neutral",
  success: "success",
  warning: "warning",
};

const positions = {
  bottomLeft: "bottomLeft",
  topLeft: "topLeft",
  topRight: "topRight",
};

const toasterPropTypes = {
  dataTestId: PropTypes.string,
  buttonClickHandler: PropTypes.func,
  buttonLabel: PropTypes.string,
  buttonLink: (props, _propName, componentName) => {
    if (props.buttonLink && props.buttonOnClick) {
      return new Error(
        `Cannot specify both "buttonLink" and "buttonOnClick" in ${componentName}.`,
      );
    }
    if (props.buttonLink && typeof props.buttonLink !== "string") {
      return new Error(
        `Invalid prop "buttonLink" supplied to ${componentName}. Should be a string.`,
      );
    }
  },
  buttonOnClick: (props, _propName, componentName) => {
    if (props.buttonLink && props.buttonOnClick) {
      return new Error(
        `Cannot specify both buttonLink and "buttonOnClick" in ${componentName}.`,
      );
    }
    if (props.buttonOnClick && typeof props.buttonOnClick !== "function") {
      return new Error(
        `Invalid prop "buttonOnClick" supplied to ${componentName}. Should be a function.`,
      );
    }
  },
  clearToast: PropTypes.func.isRequired,
  closeButtonClickHandler: PropTypes.func,
  intent: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

const Toast = ({
  dataTestId = "Toast",
  id,
  buttonClickHandler = () => undefined,
  buttonLabel,
  buttonLink,
  buttonOnClick,
  clearToast,
  closeButtonClickHandler = () => undefined,
  intent,
  text,
  title,
}) => {
  const toastRef = useRef();

  const handlers = useSwipeable({
    onSwiped: () => {
      clearToast();
    },
    onSwiping: ({ deltaX }) => {
      toastRef.current.style = `transform: rotate(${
        deltaX / 50
      }deg) translateX(${deltaX}px)`;
    },
  });

  const refPassthrough = (el) => {
    handlers.ref(el);
    toastRef.current = el;
  };

  return (
    <div
      className="Toast"
      data-testid={dataTestId}
      data-intent={intent}
      data-toast-id={id}
      {...handlers}
      ref={refPassthrough}
    >
      <button
        className="Toast-closeButton"
        onClick={() => {
          closeButtonClickHandler();
          clearToast();
        }}
      >
        <Close width={18} height={18} />
      </button>
      <StatusCard intent={intent}>
        <div className="Toast-content">
          {title && (
            <h3 data-testid="Toast-title" className="Toast-title">
              {title}
            </h3>
          )}
          {text && <p className="Toast-text">{text}</p>}
          {buttonLabel && (
            <span
              className="Toast-buttonWrapper"
              onClick={(e) => {
                clearToast();
                buttonClickHandler(e);
              }}
            >
              {buttonLink && (
                <Link
                  data-testid="Toast-button"
                  className="Toast-button"
                  href={buttonLink}
                  colorVariant="secondary"
                  size="medium"
                >
                  {buttonLabel}
                </Link>
              )}
              {buttonOnClick && (
                <Button
                  data-testid="Toast-button"
                  className="Toast-button"
                  onClick={buttonOnClick}
                  colorVariant="secondary"
                  size="medium"
                >
                  {buttonLabel}
                </Button>
              )}
            </span>
          )}
        </div>
      </StatusCard>
    </div>
  );
};

Toast.propTypes = toasterPropTypes;

const generateId = () => {
  return Math.random();
};

const createToastProps = ({
  text = "",
  intent = intents.neutral,
  id = generateId(),
  timer,
  ...rest
} = {}) => {
  return {
    ...rest,
    text,
    intent,
    id,
    timer,
  };
};

const toasterContainerPropTypes = {
  onMounted: PropTypes.func.isRequired,
  ToastComponent: PropTypes.func.isRequired,
};

const ToasterContainer = ({ onMounted, ToastComponent }) => {
  const [toasts, setToasts] = React.useState([]);
  const clear = React.useCallback(
    (id) => {
      const getFilteredPrevToast = (id, prevToasts) => {
        const toastProps = prevToasts.find((prevToast) => id === prevToast.id);
        if (toastProps?.timer) {
          clearTimeout(toastProps.timer);
        }
        return prevToasts.filter((prevToast) => id !== prevToast.id);
      };

      if (id) {
        toasts.filter((props) => id !== props.id);
        flushSync(() => {
          setToasts((prevToasts) => {
            return getFilteredPrevToast(id, prevToasts);
          });
        });
      } else {
        toasts.forEach((toastProps) => {
          if (toastProps && toastProps.timer) {
            clearTimeout(toastProps.timer);
          }
        });
        setToasts([]);
      }
    },
    [toasts],
  );

  const add = React.useCallback(
    ({ timeout = 5000, ...props } = {}) => {
      const id = props.id || generateId();

      let timer = () => undefined;
      if (timeout) {
        timer = setTimeout(() => {
          clear(id);
        }, timeout);
      }
      const toastProps = createToastProps({ id, timer, ...props });
      setToasts((prevToasts) => [...prevToasts, toastProps]);
      return id;
    },
    [clear],
  );

  React.useEffect(() => {
    if (onMounted) {
      onMounted({
        add,
        clear,
      });
    }
  }, [onMounted, toasts, add, clear]);

  return (
    <ul className="Toaster-list">
      <TransitionGroup component={null}>
        {toasts.map((toastProp) => {
          const clearToast = () => {
            clear(toastProp.id);
          };
          return (
            <CSSTransition
              key={toastProp.id}
              timeout={300}
              classNames="Toast-Container"
            >
              <li key={toastProp.id} className="Toast-Container">
                <ToastComponent clearToast={clearToast} {...toastProp} />
              </li>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </ul>
  );
};

ToasterContainer.propTypes = toasterContainerPropTypes;

const createToasterInstance = ({
  position = positions.bottomLeft,
  ToastComponent = Toast,
} = {}) => {
  const toasterQueue = [];

  // The toaster might not yet initialised.
  // So, we're adding parameters into queue in order to call them later.
  const res = {
    add: (params) => {
      toasterQueue.push({
        method: "add",
        params,
      });
    },
    clear: (params) => {
      toasterQueue.push({
        method: "clear",
        params,
      });
    },
  };

  if (canUseDOM()) {
    const el = document.createElement("aside");
    el.classList.add("Toaster-container");
    el.classList.add(`Toaster-container--${position}`);
    el.classList.add("Toaster-container--mobile");
    document.body.appendChild(el);

    createRoot(el).render(
      <ToasterContainer
        ToastComponent={ToastComponent}
        onMounted={(resNew) => {
          Object.assign(res, resNew);

          // Clear the queue
          for (let i = 0; i < toasterQueue.length; i++) {
            const queueItem = toasterQueue.pop();
            res[queueItem.method](queueItem.params);
          }
        }}
      />,
    );
  }

  return res;
};

export default {
  createToasterInstance,
  positions,
  intents,
  basicToaster: createToasterInstance(),
};
