import { misc } from "@wunderflats/constants";
import _isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import promiseMemo from "promise-memoize";
import qs from "qs";
import { EXPERIMENT_NAME_RANKING } from "../../../../analytics/experiments";
import seoCollections from "../../../../assets/seo-collections.json";
import RedirectError from "../../../../errors/redirectError";
import dataLayerUtils from "../../../../utils/data-layer";
import date from "../../../../utils/date";
import * as defaultListingFilter from "../../../../utils/default-listing-filter";
import {
  createBboxFromCenter,
  createCityBboxFromCenter,
  fetchSuggestions,
  getBboxStringFromArray,
} from "../../../../utils/geocodingHelpers";
import { mapRegionNameToEnglishSlug } from "../../../../utils/region-name-mapping";
import { safeDecodeComponent } from "../../../../utils/url";
import getWFConfig from "../../../../utils/WF_CONFIG";
import { getRelatedRegions } from "./utils";

const WF_CONFIG = getWFConfig();
const ENV = WF_CONFIG?.NODE_ENV;
const IS_TEST_ENV = ENV === "test";

const normalizeLocation = (location) => {
  if (!location) return null;
  const decodedLocation = safeDecodeComponent(location);
  return mapRegionNameToEnglishSlug(decodedLocation.toLowerCase());
};

const getSeoConfigForPath = (categorySlug, lang, location) => {
  return seoCollections.find((item) => {
    const slugParts = item.slug.split("/");
    const lastPart = slugParts[slugParts.length - 1];

    const isParisDistrict =
      location?.includes("arrondissement") && item.location === location;

    const isOtherLocation =
      item.filterParameters?.search?.startsWith("locality.");

    if (isParisDistrict) {
      return item.slug.startsWith(`${lang}/`);
    }

    if (isOtherLocation) {
      return (
        item.slug === `${lang}/${slugParts[1]}/${location}/${categorySlug}`
      );
    }

    const matchesLang = item.slug.startsWith(`${lang}/`);
    const matchesSlug = lastPart === categorySlug;
    const matchesLocation =
      !location ||
      !item.location ||
      normalizeLocation(location) === normalizeLocation(item.location);

    return matchesLang && matchesSlug && matchesLocation;
  });
};

const getContentMemo = promiseMemo(
  async (api, lang, region, category) => {
    let content = null;
    try {
      content = await api.regions.getContent(lang, region, category);
    } catch (err) {
      content = {};
    }
    return content;
  },
  /** Invalidating memoization on the test envionment.
   * Otherwise, tests will memoize values from the previous tests
   * and potentially provide incorrect values, causing flaky behavior.
   * Note that maxAge can't be set to 0 because all falsy values
   * are treated as if the user didn't specify an argument and default
   * value seems to be used in that case.
   */
  { maxAge: IS_TEST_ENV ? 0.00001 : 60000 },
);

const getMunicipalitiesMemo = promiseMemo(
  (api, federalState) => {
    return api.municipalities.getByFederalState(federalState);
  },
  /** Invalidating memoization on the test envionment.
   * Otherwise, tests will memoize values from the previous tests
   * and potentially provide incorrect values, causing flaky behavior.
   * Note that maxAge can't be set to 0 because all falsy values
   * are treated as if the user didn't specify an argument and default
   * value seems to be used in that case.
   */
  { maxAge: IS_TEST_ENV ? 0.00001 : 60000 },
);

const getRegionsMemo = promiseMemo(
  (api) => {
    return api.listings.getRegions();
  },
  /** Invalidating memoization on the test envionment.
   * Otherwise, tests will memoize values from the previous tests
   * and potentially provide incorrect values, causing flaky behavior.
   * Note that maxAge can't be set to 0 because all falsy values
   * are treated as if the user didn't specify an argument and default
   * value seems to be used in that case.
   */
  { maxAge: IS_TEST_ENV ? 0.00001 : 60000 },
);

const getCategoriesMemo = promiseMemo(
  (api, lang) => {
    return api.categories.getCategories({ lang });
  },
  /** Invalidating memoization on the test envionment.
   * Otherwise, tests will memoize values from the previous tests
   * and potentially provide incorrect values, causing flaky behavior.
   * Note that maxAge can't be set to 0 because all falsy values
   * are treated as if the user didn't specify an argument and default
   * value seems to be used in that case.
   */
  { maxAge: IS_TEST_ENV ? 0.00001 : 60000 },
);

const getStructuredDataByRegionSlugMemo = promiseMemo(
  (api, region) => {
    return api.listings.getStructuredDataByRegionSlug(region);
  },
  /** Invalidating memoization on the test envionment.
   * Otherwise, tests will memoize values from the previous tests
   * and potentially provide incorrect values, causing flaky behavior.
   * Note that maxAge can't be set to 0 because all falsy values
   * are treated as if the user didn't specify an argument and default
   * value seems to be used in that case.
   */
  { maxAge: IS_TEST_ENV ? 0.00001 : 1000 * 60 * 60 * 24 }, // 24 Hours
);

const getFirstSuggestion = async (toSearch, lang) => {
  let suggestions;
  try {
    ({ suggestions } = await fetchSuggestions({ text: toSearch, lang }));
    const [firstSuggestion] = suggestions;
    const { bbox, region, placeType, country } = firstSuggestion || {};
    const _bbox = bbox || createBboxFromCenter(firstSuggestion.center);

    return {
      suggestionBbox: getBboxStringFromArray(_bbox, true),
      region,
      placeType,
      country,
    };
  } catch (e) {
    console.error(
      "firstSuggestionBBox error, in ListingsPage, while searching:",
      toSearch,
      "lang:",
      lang,
      "fetched suggestions:",
      suggestions,
    );
    console.error(e);
  }
};

export default async ({
  api,
  params = {},
  query = {},
  originalUrl,
  cookies,
}) => {
  // Do not remove scoreVariant - permanent ranking A/B test
  if (!query.scoreVariant) {
    const scoreVariant = cookies?.experiments
      ? JSON.parse(cookies.experiments)?.[EXPERIMENT_NAME_RANKING]
      : "A";
    query.scoreVariant = scoreVariant;
  }

  const queryDatesCheckResult = date.checksIfQueryDatesAreValid(query);
  const [urlPath] = originalUrl.split("?");

  const englishRegionSlug = mapRegionNameToEnglishSlug(params.region);

  if (!queryDatesCheckResult.isValid) {
    const newQuery = {
      ...omit(query, ["from", "to"]),
      ...omit(queryDatesCheckResult, ["isValid"]),
    };
    const fromToRemoved = qs.stringify(newQuery, {
      addQueryPrefix: true,
    });
    const redirectTo = `${urlPath}${fromToRemoved || ""}`;
    throw new RedirectError({ redirectTo, status: 301 });
  }

  // fetch all categories
  const categoriesResponse = await getCategoriesMemo(api, params.lang);
  const categories = categoriesResponse?.categories || [];

  // if there's a category param in the URL, find the matching category
  let category;
  const seoConfig = getSeoConfigForPath(
    params.category,
    params.lang,
    params.region,
  );

  if (params.category) {
    category = categories.find((cat) => cat.slug === params.category);
    if (!seoConfig && !category) {
      // If there is a category param in the URL, but the category doesn't exist in our list, show error
      const error = new Error("ResourceNotFoundError");
      error.name = "ResourceNotFoundError";
      throw error;
    }
  }

  if (seoConfig) {
    category = {
      ...category,
      slug: params.category || params.region,
      label: seoConfig.filterParameters?.labels || category?.label || null,
      title: seoConfig.title || category?.title,
      heading1: seoConfig.heading1,
      heading2: seoConfig.heading2,
      filterParameters: seoConfig.filterParameters,
      noIndex: seoConfig.noIndex,
      hreflang: seoConfig.hreflang,
    };
  }

  if (params.category) {
    query.labels = category.label;
  }

  if (category?.filterParameters) {
    query = {
      ...query,
      ...category.filterParameters,
      ...(query.search ? { search: query.search } : {}),
      ...(query.bbox ? { bbox: query.bbox } : {}),
    };
  }
  // end categories

  const regions = await getRegionsMemo(api);

  let regionExists = true;
  let region;
  if (!query.search && !query.bbox) {
    region = regions.items.find((region) => region.slug === englishRegionSlug);
    regionExists = !!region;
  }

  // Checks if the region slug in URL exists.
  // If it's not then we're throwing ResourceNotFoundError.
  if (!regionExists) {
    const error = new Error("ResourceNotFoundError");
    error.name = "ResourceNotFoundError";
    throw error;
  }

  let currentMunicipality = null;
  if (
    query.federalState &&
    query.municipality &&
    category?.label === misc.labels.CATEGORY_HOMES_FOR_UKRAINIANS
  ) {
    try {
      const municipalities = await getMunicipalitiesMemo(
        api,
        query.federalState,
      );

      currentMunicipality =
        municipalities.find(
          (municipality) => municipality.id === query.municipality,
        ) || null;
    } catch (_error) {
      // Ignore
    }
  }

  /**
   * searchedPlaceBbox is used for centering the map
   * and request the listings of a previously searched place
   */
  let searchedPlaceBbox;

  /**
   * searchBiasBbox is used for biasing the GeocodingSearch component
   * and returning the suggestions sorted by proximity to this bbox
   */
  let searchBiasBbox;

  let placeType;
  let cityOrZipCode;
  let minBookingDurationQuery = {};

  if (query.bbox) {
    // The previous place is already a bbox, we can use that for biasing the GeocodingSearch component
    searchBiasBbox = query.bbox;
  } else if (query.search) {
    // There was a previous search, we search the bbox of that place for biasing the GeocodingSearch component
    let firstSuggestion = await getFirstSuggestion(query.search, params.lang);
    let suggestionBbox = firstSuggestion?.suggestionBbox;

    cityOrZipCode = firstSuggestion?.region;
    placeType = firstSuggestion?.placeType;

    if (!suggestionBbox) {
      // the search by query.search (so by Mapbox ID) failed
      // So we repeat the search via text params.region (e.g.: /Berlin%2C%20Germany)
      firstSuggestion = await getFirstSuggestion(
        safeDecodeComponent(params.region),
        params.lang,
      );

      suggestionBbox = firstSuggestion?.suggestionBbox;
      cityOrZipCode = firstSuggestion?.region;
      placeType = firstSuggestion?.placeType;
    }

    searchBiasBbox = suggestionBbox;
    searchedPlaceBbox = suggestionBbox;
  } else if (!query.bbox && !query.search) {
    // there was no previous search, so we get the bias
    // by searching for the region slug in the URL, e.g.: /berlin
    const firstSuggestion = await getFirstSuggestion(
      safeDecodeComponent(englishRegionSlug),
      params.lang,
    );

    searchBiasBbox = firstSuggestion?.suggestionBbox;
    cityOrZipCode = firstSuggestion?.region;
    placeType = firstSuggestion?.placeType;

    if (regionExists) {
      // TODO this is a quick fix for solving the BUG: https://wunderflats.atlassian.net/browse/BUG-1775. But this has to change (we may think to implement a better solution, like searching adding more context to getFirstSuggestionBbox (e.g.: search for regionTranslation+'germany')
      const bboxArray = region.bbox || createCityBboxFromCenter(region.center);
      searchedPlaceBbox = getBboxStringFromArray(bboxArray);
    } else {
      searchedPlaceBbox = searchBiasBbox;
    }
  }

  if (placeType?.[0] === "postcode") {
    minBookingDurationQuery = { zipCode: cityOrZipCode };
  } else {
    minBookingDurationQuery = { city: cityOrZipCode };
  }

  const regionOrBbox = query.bbox || searchedPlaceBbox || englishRegionSlug;

  const listingsResultsPromise = api.listings
    .getListingsForRegion(regionOrBbox, params.page, query)
    .catch((error) => {
      /*
      If we encounter a validation error,
      this is most likely because of badly formatted query.
      It happens often with crawler coming back with out of date
      amenities for an example.
      In that case, redirect to default SRP instead of showing
      an error page
      */
      if (error.name === "ValidationError") {
        throw new RedirectError({ redirectTo: urlPath });
      } else {
        console.error(error);
      }
    });

  const promises = await Promise.allSettled([
    getStructuredDataByRegionSlugMemo(api, englishRegionSlug),
    getContentMemo(api, params.lang, params.region, category?.label),
    api.listings.getMinBookingDuration(minBookingDurationQuery),
  ]);
  const [structuredData, content, minBookingDuration] = (promises || []).map(
    (p) => p.value || {},
  );

  const listingResults = await listingsResultsPromise;

  const seoMetadata = category
    ? {
        title: category.title,
        heading1: category.heading1,
        heading2: category.heading2,
        noIndex: category.noIndex,
        filterParameters: category.filterParameters,
        hreflang: category?.hreflang,
      }
    : null;

  const defaultFilters = defaultListingFilter.getFromCookies(cookies);

  return {
    regions: regions.items,
    regionDistricts: getRelatedRegions(region, regions),
    listingResults,
    englishRegionSlug,
    structuredData,
    content,
    category,
    categories,
    seoMetadata,
    dataLayerKey: dataLayerUtils.generateDataLayerKey(),
    searchBiasBbox,
    searchedPlaceBbox,
    defaultFilters,
    minBookingDuration: minBookingDuration.minBookingDuration,
    currentMunicipality,
    regionBbox: regionOrBbox,
  };
};

export const shouldListingsBeReloaded = (prev, curr) => {
  function extractNonReloadParams({ params, query }) {
    return {
      params,
      query: omit(query, ["view", "listing"]),
    };
  }
  /**
   * We do not want to reload the entire page when the view (map, list or filters) changes.
   * We do not want to reload the page when a user highlights a listing on the map.
   * So we take both these params out of the url that we will use to check if the page
   * should reload.
   */

  return !_isEqual(extractNonReloadParams(prev), extractNonReloadParams(curr));
};
