import { getSystemLanguages } from "../../../../routes";
import { isCurrentPage } from "../../../../utils/url";

export const getRedirectRouteName = ({ redirect, lang = "en" }) => {
  const splittedRedirect = redirect.split("?")[0];

  const routes = [
    { key: "shortlist", name: "shortlist" },
    { key: "dashboard", name: "dashboard" },
    { key: "createListing", name: "createListing" },
    { key: "createListingRefugee", name: "createListingRefugee" },
    { key: "createImmocationListing", name: "createImmocationListing" },
    { key: { name: "inviteLandlord", lang }, name: "inviteLandlord" },
    { key: { name: "referralLanding", lang }, name: "referralLanding" },
    { key: { name: "landlords", lang }, name: "landlords" },
    { key: { name: "explorePage", lang }, name: "explorePage" },
  ];

  const matchingRoute = routes.find((route) =>
    isCurrentPage(splittedRedirect, route.key),
  );

  return matchingRoute ? matchingRoute.name : null;
};

export const isLandlordPage = ({ redirect, lang = "en" }) => {
  const route = getRedirectRouteName({ redirect, lang });
  const landlordRoutes = [
    "dashboard",
    "createListing",
    "createListingRefugee",
    "createImmocationListing",
    "inviteLandlord",
    "referralLanding",
    "landlords",
    "explorePage",
  ];

  return landlordRoutes.includes(route);
};

export const isBookingWizardPage = ({ redirect, lang }) => {
  const splittedRedirect = redirect.split("?")[0];

  return isCurrentPage(splittedRedirect, { name: "book", lang });
};

export const getEventLabel = ({ redirect, lang = "en", isMobile = false }) => {
  const route = getRedirectRouteName({ redirect, lang });

  let label = "HomePage";
  if (isMobile) {
    label = "LL Signup Mobile App";
  } else if (route === "shortlist") {
    label = "Shortlist";
  } else if (isLandlordPage({ redirect, lang })) {
    label = "LL Signup";
  } else if (isBookingWizardPage({ redirect, lang })) {
    label = "BookingWizard";
  }

  return label;
};

export const getLanguageCodeFromLang = (lang) => {
  if (!lang) return null;

  return lang.includes("fr") ? "FR" : "DE";
};

export const getCountryCodeFromRedirectUrl = (redirect) => {
  if (!redirect) return null;

  const redirectUrlParam = new URLSearchParams(redirect);
  const languages = getSystemLanguages()?.join("|");

  const patterns = {
    normalListing: new RegExp(`\\/(${languages})\\/create\\/address\\?address`),
    refugeeListing: new RegExp(
      `\\/(${languages})\\/support-ukraine\\/address\\?address`,
    ),
    immocationListing: new RegExp(
      `\\/(${languages})\\/immocation\\/address\\?address`,
    ),
  };

  const matchedPattern = Object.values(patterns).find((pattern) =>
    pattern.test(redirect),
  );

  if (!matchedPattern) return null;

  const matches = redirect.match(matchedPattern);
  if (!matches) return null;

  const addressParam = redirectUrlParam.get(matches[0]);
  if (!addressParam) return null;

  try {
    return JSON.parse(addressParam).country;
  } catch (e) {
    console.error("Failed to parse address parameter:", e);
    return null;
  }
};
